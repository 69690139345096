import axios from 'axios';
import { userconst } from '../../../reducers/user';
import { triageApi } from '../../../api/triage';

export default ({
  graph_id,
  sequence_id, // need this
  tile_id, // need this
  experience = `default`, // need this
  server_url = triageApi.url,
}) => {
  const url = `${server_url}api/${triageApi.version_2}/graphs/${graph_id}/sequences/${sequence_id}/tiles/${tile_id}/pdf_preview?experience=${experience}`;

  axios({
    method: 'GET',
    url: url,
    headers: {
      Authentication: localStorage.getItem(userconst.token),
    },
    responseType: 'blob', // important
  }).then(({ data }) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `informed_consent_pdf_preview.pdf`);
    link.click();
    window.URL.revokeObjectURL(url);
  });
};
