import add_reminder, {
  add_reminder_incoming,
  add_reminder_outgoing,
} from './add_reminder';
import no_type, { no_type_incoming, no_type_outgoing } from './no_type';

import add_archipelago from './add_archipelago';
import api_request from './api_request';
import assign_external_id from './assign_external_id';
import connect_app from './connect_app';
import connect_external from './connect_external';
import delay from './delay';
import disconnect_app from './disconnect_app';
import education from './education';
import email_message from './email_message';
import emit_event from './emit_event';
import evaluate_cap from './evaluate_cap';
import event_delay from './event_delay';
import follow_up_survey from './follow_up_survey';
import graph from './graph';
import informed_consent from './informed_consent';
import journal from './journal';
import milestone from './milestone';
import mtl_kit_order from './mtl_kit_order';
import multi_factor_authenticate from './multi_factor_authenticate';
import registration from './registration';
import screener from './screener';
import sequence from './sequence';
import sms_message from './sms_message';
import survey from './survey';
import take_survey from './take_survey';
import terminal_state from './terminal_state';
import tile from './tile';
import verify_email from './verify_email';
import visit_portal from './visit_portal';
import connect_ehr from './connect_ehr';
import communications from './communications';
import connect_camcog from './camcog';
import hibbert_shipping from './hibbert_shipping';
import experian_validator from './experian_validator';
import qualtrics_survey from './qualtrics_survey';
import castor_edc from './castor_edc';
import castor_epro_survey from './castor_epro_survey';
import reminder from './reminder';

export default {
  no_type: {
    form: {
      Content: no_type,
      Settings: no_type,
    },
    incoming: no_type_incoming,
    outgoing: no_type_outgoing,
  },
  add_reminder: {
    form: add_reminder,
    incoming: add_reminder_incoming,
    outgoing: add_reminder_outgoing,
  },
  tile: {
    form: {
      Content: tile,
      Settings: tile,
    },
  },
  add_archipelago,
  api_request,
  survey,
  follow_up_survey,
  graph,
  sequence,
  screener,
  journal,
  connect_app,
  connect_external,
  delay,
  disconnect_app,
  education,
  connect_ehr,
  email_message,
  event_delay,
  emit_event,
  evaluate_cap,
  informed_consent,
  milestone,
  mtl_kit_order,
  registration,
  take_survey,
  terminal_state,
  visit_portal,
  verify_email,
  sms_message,
  multi_factor_authenticate,
  assign_external_id,
  communications,
  connect_camcog,
  hibbert_shipping,
  experian_validator,
  qualtrics_survey,
  castor_edc,
  castor_epro_survey,
  reminder,
};
